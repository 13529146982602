import React from "react"
import Box from "@material-ui/core/Box";
import UnderConstruct from '../images/under-construction.gif'

export default function UnderConstruction() {
  return (
      <Box height={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <img src={UnderConstruct} alt='' style={{width: 800, height: 600}}/>
      </Box>
  )
}
